import {
  createClient as CreateClient,
  dedupExchange,
  fetchExchange,
  cacheExchange,
} from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';
import axios from 'axios';
import { pipe } from 'fp-ts/lib/function';
import { alt, getOrElse, map } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import { Eq as StringEq } from 'fp-ts/lib/string';
import {
  getAuthToken,
  resetAuthentication,
  setAuthToken,
  useAuth,
} from '../../stores/auth';
import { useLocale } from '../../stores/locale';

export const contentful = CreateClient({
  url: `${process.env.NEXT_PUBLIC_API_URL}/api/content/v1/contentful/graphql`,
  exchanges: [
    dedupExchange,
    cacheExchange,
    retryExchange({
      retryWith: (error, operation) => {
        if (
          error.networkError &&
          error.response &&
          error.response.status === 429
        ) {
          const delay = +(
            error.response.headers.get('X-Contentful-RateLimit-Reset') || 1
          );

          return {
            ...operation,
            context: {
              ...operation.context,
              retryDelay: delay * 1000,
            },
          };
        }

        return operation;
      },
    }),
    fetchExchange,
  ],
  requestPolicy: 'cache-and-network',
  fetchOptions: {
    headers: {
      'x-azure-api-secret': process.env.NEXT_API_SECRET || '',
    },
  },
});

const headers = process.env.NEXT_API_SECRET
  ? {
      'x-azure-api-secret': process.env.NEXT_API_SECRET,
    }
  : undefined;

export const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api`,
  headers,
  paramsSerializer: {
    indexes: null, // no brackets when sending lists
  },
});

api.interceptors.request.use((config) => {
  const { isAuthenticated, token } = useAuth.getState();
  if (isAuthenticated) {
    config.headers.set('Authorization', `Bearer ${token}`);
  }
  config.headers.set('x-lng', useLocale.getState().locale);

  if (process.env.NEXT_API_SECRET) {
    config.headers.set('x-azure-api-secret', process.env.NEXT_API_SECRET);
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    const currentAuthToken = getAuthToken();
    const authToken = pipe(
      response.headers as Record<string, string>,
      lookup('Authorization'),
      alt(() =>
        pipe(
          response.headers as Record<string, string>,
          lookup('authorization')
        )
      ),
      map((authToken) => authToken.replace('Bearer ', '')),
      getOrElse(() => currentAuthToken)
    );

    if (!StringEq.equals(authToken, currentAuthToken)) {
      setAuthToken(authToken);
    }

    return response;
  },
  (error) => {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error);
    }
    if (error.response?.status === 401) {
      resetAuthentication();
    }

    return Promise.reject(error);
  }
);
